import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
//
const AnyReactComponent = ({ text }) => <h4 className={'text-danger'}>{text}</h4>;

class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lat: 40.1516595,
            lng: 44.4959044
        },
        zoom: 12
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDBflHZ2Roo3E6Ugolw2F4XN4iOPcWgTnY' }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    yesIWantToUseGoogleMapApiInternals
                >
                    <AnyReactComponent
                        lat={40.1516595}
                        lng={44.4959044}
                        text="Bisflow"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default SimpleMap;