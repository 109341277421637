import React from "react";

// reactstrap components
import {Row, Col, Container, NavbarBrand, Navbar} from "reactstrap";

// core components

function BlogPostHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <Navbar
            className="navbar-transparent bg-info navbar-absolute"
            expand="lg"
        >
          <Container>
            <div className="navbar-translate">
              <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                <img
                    alt="..."
                    style={{width: '200px', height: '50px'}}
                    src={require("assets/img/logo_white.png").default}
                ></img>
              </NavbarBrand>
            </div>
          </Container>
        </Navbar>
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg24.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Your digital workplace is one click away</h2>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default BlogPostHeader;
