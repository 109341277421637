import React from "react";
import {
    Button,
    Card, CardBody, CardFooter, CardHeader, CardTitle,
    Carousel, CarouselItem,
    Col,
    Collapse,
    Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import InitMap from "./InitMap";

const MapWrapper = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let lat = "40.748817";
        let lng = "-73.985428";
        const myLatlng = new google.maps.LatLng(lat, lng);
        const mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel: false,
            zoomControl: true,
            styles: [
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
                },
                {
                    featureType: "landscape",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#ffffff" }, { lightness: 17 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
                },
                {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 18 }],
                },
                {
                    featureType: "road.local",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 16 }],
                },
                {
                    featureType: "poi",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#dedede" }, { lightness: 21 }],
                },
                {
                    elementType: "labels.text.stroke",
                    stylers: [
                        { visibility: "on" },
                        { color: "#ffffff" },
                        { lightness: 16 },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        { saturation: 36 },
                        { color: "#333333" },
                        { lightness: 40 },
                    ],
                },
                { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#fefefe" }, { lightness: 20 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
                },
            ],
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Now UI Kit PRO React!",
        });

        const contentString =
            '<div class="info-window-content"><h2>Now UI Kit PRO React</h2>' +
            "<p>A premium Admin for React, Reactstrap, and React Hooks.</p></div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
        });
    });
    return (
        <>
            <div style={{ height: `100%` }} ref={mapRef}></div>
        </>
    );
};

const items = [
    {
        src: "0",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons business_money-coins'}></i>
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Lower your costs, save time and elevate the customer experience."
                    </h5>
                </CardBody>
            </Card>
        ),
    },
    {
        src: "1",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons gestures_tap-01'} />
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Create your digital signature and use it whenever you need from any device."
                    </h5>
                </CardBody>
            </Card>
        ),
    },
    {
        src: "2",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons objects_globe'} />
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Creating business partnership in minutes with frictionless contract management."
                    </h5>
                </CardBody>
            </Card>
        ),
    }
];

function MainLanding() {

    const [navbarOpen1, setNavbarOpen1] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [first1Focus, setFirst1Focus] = React.useState(false);
    const [last1Focus, setLast1Focus] = React.useState(false);
    const [email1Focus, setEmail1Focus] = React.useState(false);
    const [first2Focus, setFirst2Focus] = React.useState(false);
    const [email2Focus, setEmail2Focus] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    return (
        <div className="cd-section" id="headers">
            <div className="header-3">
                <Navbar
                    className="navbar-transparent bg-info navbar-absolute"
                    expand="lg"
                >
                    <Container>
                        <div className="navbar-translate">
                            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img
                                    alt="..."
                                    style={{width: '120px', height: '40px'}}
                                    src={require("assets/img/logo_white.png").default}
                                ></img>
                            </NavbarBrand>
                        </div>
                    </Container>
                </Navbar>
            </div>
                <div className="page-header page-header-small">
                    <div
                        className="page-header-image"
                        style={{
                            backgroundImage: "url(" + require("assets/img/bg37.jpg").default + ")",
                        }}
                    />
                    <div className="content">
                        <Container className="text-left">
                            <Row>
                                <Col className="ml-auto mr-auto text-right mt-5" md="6">
                                    <h1 className={'title'} style={{textAlign: 'start'}}>Contract Lifecycle Management</h1>
                                    <h4 style={{textAlign: 'justify'}}>
                                        Bisflow Contract Lifecycle Management (CLM) is the contract automation platform that empowers legal & business teams to streamline routine contract workflow in one unified workspace.
                                    </h4>
                                </Col>
                                <Col md="5">
                                    <div className="iframe-container">
                                        <iframe
                                            title="myIframe"
                                            allowFullScreen=""
                                            frameBorder="0"
                                            height="250"
                                            src="https://www.youtube.com/embed/3UPIr_Zc2Vk?rel=0&amp;controls=0&amp;showinfo=0"
                                        ></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            </div>
            <div className="features-3">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10">
                            <h2 className="title">Simpler. Smarter. Faster.</h2>
                            <h4 className="description" style={{textAlign: 'justify'}}>
                                It can take hundreds of steps across multiple tools just to get one contract safely agreed. These manual processes are painful for all departments of your business. Unlike legacy contract management platforms, which are designed for handling offline files, Bisflow CLM allows you to query your contract data at any time, as well as removing information silos across teams.
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-success icon-circle">
                                    <i className="now-ui-icons tech_laptop"></i>
                                </div>
                                <h4 className="info-title">All-in-one contract automation</h4>
                                <p className="description">
                                    Bisflow CLM helps legal and business teams to collaborate securely, in one platform. Our workflow builder puts everyone on the same place and helps you keep control.
                                </p>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-primary icon-circle">
                                    <i className="now-ui-icons files_single-copy-04"></i>
                                </div>
                                <h4 className="info-title">Flexible, configurable workflow</h4>
                                <p className="description">
                                    Allow business users to design and automate complex agreement processes to eliminate bottlenecks and automate manual hand offs.
                                </p>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-info icon-circle">
                                    <i className="now-ui-icons ui-2_chat-round"></i>
                                </div>
                                <h4 className="info-title">Fosters collaboration</h4>
                                <p className="description">
                                    Send contracts for review, track changes across multiple versions, and maintain a single source of truth for who did what, when.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'bg-light'}>
                <Row>
                    <Col md={12} className={'text-center'}>
                        <h2 className="title text-center">Features</h2>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Row>
                    <Col md={12} className={'align-center text-center'}>
                        <p className={'text-center'}>Bisflow CLM replaces Word, EKENG, DocuSign, Gdocs and more to streamline contracts and eliminate bottlenecks.</p>
                    </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                <Container fluid>
                    <Row className={'mt--4'}>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-info"><strong>Seamless document generation</strong></h5>
                                    <p>
                                        Create preconfigured templates that auto-populate contracts with data from the system and approved language.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-danger"><strong>Customize workflow templates</strong></h4>
                                    <p>
                                        Build new contract workflows and deliver quicker-time-to-value with pre-defined, standard templates for approvals, redlining, signature and routing.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" className={'mt-5 pt-5'}>
                            <div className="phone-container pt-5 mt-5">
                                <img
                                    alt="..."
                                    src={require("assets/img/procurement.png").default}
                                ></img>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-success"><strong>Centralized repository</strong></h5>
                                    <p>
                                        Store, tag and organize contracts in one searchable place with granular permission controls. Increase oversight and find contracts faster.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-warning"><strong>Digital signature</strong></h4>
                                    <p>
                                        Bisflow Digital Signature accelerates agreements, eliminates manual tasks, and makes it easy to connect with the tools and systems you’re already using.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </Row>
            </div>


            {/*<div className="features-5">*/}
            {/*    <Container>*/}
            {/*        <Row>*/}
            {/*            <Col md={12} className={'text-center'}>*/}
            {/*                <h2 className="title text-center">Features</h2>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col md={12}>*/}
            {/*                <h3>Purchase request</h3>*/}
            {/*                <p>Create, review, and manage your purchase requests with complete transparency!</p>*/}
            {/*                <p>Gain full visibility about the status of your purchase requests and get instant updates whenever there are changes.</p>*/}
            {/*                <p>With cloud storage, get instant access to your product catalogs when initiating purchase requisitions.</p>*/}
            {/*                <p>Proactively define and manage budget restrictions with dynamic rules throughout the entire procure-to-pay lifecycle.</p>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col md="7">*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons location_world"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Approvals on the go </h4>*/}
            {/*                        <p>*/}
            {/*                            Every stakeholder has the ability to provide approvals: anytime, anywhere, on any device.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons sport_user-run"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Requisitions to RFQ</h4>*/}
            {/*                        <p>*/}
            {/*                            Convert your purchase requisitions to a new request for proposal (RFQ) in a click.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons ui-2_time-alarm"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Automated routing</h4>*/}
            {/*                        <p>*/}
            {/*                            Move requests through the chain of hierarchy without any manual intervention.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons ui-2_time-alarm"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Policy compliance</h4>*/}
            {/*                        <p>*/}
            {/*                            Enforce organizational and legal policies easily with digital purchase requisitions.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*            <Col md="5">*/}
            {/*                <div className="tablet-container mt-5">*/}
            {/*                    <img*/}
            {/*                        alt="..."*/}
            {/*                        src={require("assets/img/proc.png").default}*/}
            {/*                    ></img>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}

            <div>
                <Container>
                    <Row className="row-grid align-items-center">
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                <Card className="shadow border-0 mt-5">
                                    <img
                                        alt="..."

                                        src={require("assets/img/legal.jpg").default}
                                    />

                                </Card>
                            </Col>
                            <Col md="6">
                                <div className="pl-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                        <i className="ni ni-paper-diploma" />
                                    </div>
                                    <h3>Legal regulation</h3>
                                    <br/>
                                    <p>
                                        According to <a href={'https://www.arlis.am/documentview.aspx?docid=142375'} className={'text-info'}><i>the 3rd part of the 450th article of RA Civil Code,</i></a> <b> all virtual contracts conducted throughout bisflow have the same juridical validation as regular physical contracts.</b>:
                                    </p>
                                    <p>
                                        The parties can also use the digital copies of their handwriting signatures while signing the contract <a href={'https://www.arlis.am/documentview.aspx?docid=142375'} className={'text-info'}>  3rd part of the 296th article of RA Civil Code).</a>։
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </div>

            <div className="testimonials-2 mt-0 pt-0 bg-light">
                <Container>
                    <Row>
                        <Col md="12">
                            <Carousel
                                activeIndex={activeIndex}
                                next={next}
                                previous={previous}
                            >
                                {items.map((item) => {
                                    return (
                                        <CarouselItem
                                            onExiting={onExiting}
                                            onExited={onExited}
                                            key={item.src}
                                        >
                                            {item.content}
                                        </CarouselItem>
                                    );
                                })}
                                <a
                                    className="carousel-control-prev"
                                    data-slide="prev"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        previous();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    data-slide="next"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        next();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                                </a>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="pricing-2 bg-light" id="pricing-2">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="6">
                            <h2 className="title">Pick the best plan for you</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Card className="card-pricing card-plain">
                                <CardBody>
                                    <h5 className="category">Business</h5>
                                    <CardTitle tag="h1">
                                        <small>֏</small>
                                        24 900
                                    </CardTitle>
                                    <h3>
                                        <small>5 users</small>
                                    </h3>
                                    <ul>
                                        <li>
                                            Custom templates
                                        </li>
                                        <li>
                                            <b>1 GB</b> Storage size
                                        </li>
                                        <li>
                                            e-signature
                                        </li>
                                        <li>
                                            Single-language
                                        </li>
                                        <li>
                                            Basic CLM
                                        </li>
                                    </ul>
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Sign Up
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card
                                className="card-pricing card-background card-raised"
                                style={{
                                    backgroundImage:
                                        "url(" + require("assets/img/pricing3.jpg").default + ")",
                                }}
                            >
                                <CardBody>
                                    <h5 className="category text-info">Business Pro</h5>
                                    <CardTitle tag="h1">
                                        <small>֏</small>
                                        49 900
                                    </CardTitle>
                                    <h3>
                                        <small>10 Users</small>
                                    </h3>
                                    <ul>
                                        <li>
                                            Everything in Business package
                                        </li>
                                        <li>
                                            Multi-language
                                        </li>
                                        <li>
                                            Custom CLM
                                        </li>
                                        <li>
                                            Branding
                                        </li>
                                        <li>
                                            API
                                        </li>
                                    </ul>
                                    <Button
                                        className="btn-neutral btn-round"
                                        color="default"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Sign Up
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-pricing card-plain">
                                <CardBody>
                                    <h5 className="category">Special</h5>
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Call for more
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(" + require("assets/img/bg32.jpg").default + ")",
                    }}
                />
                <div className="content-center">
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h3>
                                Scaling legal across multiple markets at lightning speed.
                            </h3>
                            <Button color={'danger'}>Contact us</Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                >
                    <InitMap />
                </div>
                <div>
                    <Row>
                        <Col lg="5" md="10" className="mt-5 ml-5">
                            <Card className="card-contact card-raised">
                                <Form id="contact-form" method="post" role="form">
                                    <CardHeader className="text-center">
                                        <CardTitle tag="h4">Contact Us</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="5">
                                                <div className="info info-horizontal">
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons tech_mobile"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Give us a ring</h5>
                                                        <p>
                                                            +37495 222238 <br></br>
                                                            Mon - Fri, 9:00 – 18:00
                                                        </p>
                                                        <p><small>Email:</small> info@bisflow.io</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="7">
                                                <div className="info info-horizontal">
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons location_pin"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Find us at the office</h5>
                                                        <p>
                                                            34 Business Centre, 2d Flore, <br></br>
                                                            34 Garegin Nzhdeh street, <br></br>
                                                            Yerevan, Armenia
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default MainLanding;