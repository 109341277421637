import React from "react";
import {
    Button,
    Card, CardBody, CardFooter, CardHeader, CardTitle,
    Carousel, CarouselItem,
    Col,
    Collapse,
    Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Row
} from "reactstrap";

import InitMap from "./InitMap";

const MapWrapper = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let lat = "40.748817";
        let lng = "-73.985428";
        const myLatlng = new google.maps.LatLng(lat, lng);
        const mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel: false,
            zoomControl: true,
            styles: [
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
                },
                {
                    featureType: "landscape",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#ffffff" }, { lightness: 17 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
                },
                {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 18 }],
                },
                {
                    featureType: "road.local",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 16 }],
                },
                {
                    featureType: "poi",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#dedede" }, { lightness: 21 }],
                },
                {
                    elementType: "labels.text.stroke",
                    stylers: [
                        { visibility: "on" },
                        { color: "#ffffff" },
                        { lightness: 16 },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        { saturation: 36 },
                        { color: "#333333" },
                        { lightness: 40 },
                    ],
                },
                { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#fefefe" }, { lightness: 20 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
                },
            ],
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Now UI Kit PRO React!",
        });

        const contentString =
            '<div class="info-window-content"><h2>Bisflow</h2>' +
            "<p>Business workflow</p></div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
        });
    });
    return (
        <>
            <div style={{ height: `100%` }} ref={mapRef}></div>
        </>
    );
};

const items = [
    {
        src: "0",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons design_bullet-list-67'}></i>
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Simplify your procurement process, eliminate inefficiencies and delays using bisflow Procurement."
                    </h5>
                </CardBody>
            </Card>
        ),
    },
    {
        src: "1",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons tech_watch-time'} />
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Cut down your purchase-to-pay processing time up to 75%."
                    </h5>
                </CardBody>
            </Card>
        ),
    }
];

function MainLanding() {

    const [navbarOpen1, setNavbarOpen1] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [first1Focus, setFirst1Focus] = React.useState(false);
    const [last1Focus, setLast1Focus] = React.useState(false);
    const [email1Focus, setEmail1Focus] = React.useState(false);
    const [first2Focus, setFirst2Focus] = React.useState(false);
    const [email2Focus, setEmail2Focus] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    return (
        <div className="cd-section" id="headers">
            <div className="header-3">
                <Navbar
                    className="navbar-transparent bg-info navbar-absolute"
                    expand="lg"
                >
                    <Container>
                        <div className="navbar-translate">
                            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img
                                    alt="..."
                                    style={{width: '120px', height: '40px'}}
                                    src={require("assets/img/logo_white.png").default}
                                ></img>
                            </NavbarBrand>
                        </div>
                    </Container>
                </Navbar>
            </div>
                <div className="page-header page-header-small">
                    <div
                        className="page-header-image"
                        style={{
                            backgroundImage: "url(" + require("assets/img/bg37.jpg").default + ")",
                        }}
                    />
                    <div className="content">
                        <Container className="text-left">
                            <Row>
                                <Col className="ml-auto mr-auto text-right mt-5" md="6">
                                    <h1 className={'title'} style={{textAlign: 'start'}}>Procurement</h1>
                                    <h4 style={{textAlign: 'justify'}}>
                                        Bisflow Procurement is a web-based solution for purchase-to-pay processes including purchase requisitions, purchase orders, vendor management, purchase invoices, analytics and reports.
                                    </h4>
                                </Col>
                                <Col md="5">
                                    <div className="iframe-container">
                                        <iframe
                                            title="myIframe"
                                            allowFullScreen=""
                                            frameBorder="0"
                                            height="250"
                                            src="https://www.youtube.com/embed/SdP_6eprt0Y?rel=0&amp;controls=0&amp;showinfo=0"
                                        ></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            </div>
            <div className="features-3">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="title">Simpler. Smarter. Faster.</h2>
                            <h4 className="description">
                                Bisflow procurement features work together to bring all your purchasing needs to one accessible hub. Here are some of the ways that can help you to implement spend controls and gain insight into purchasing and financial information.
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-success icon-circle">
                                    <i className="now-ui-icons business_money-coins"></i>
                                </div>
                                <h4 className="info-title">Cost saving</h4>
                                <p className="description">
                                    Bisflow   procurement   helps   companies   ease   their   procurement process providing cost saving solutions. Easy to customize, fast to start and intuitive to work with.
                                </p>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-primary icon-circle">
                                    <i className="now-ui-icons ui-1_lock-circle-open"></i>
                                </div>
                                <h4 className="info-title">Secure</h4>
                                <p className="description">
                                    Companies   seeking   a   cost-effective   procurement   management solution to streamline their entire purchasing process with top security.
                                </p>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-info icon-circle">
                                    <i className="now-ui-icons tech_watch-time"></i>
                                </div>
                                <h4 className="info-title">Fast</h4>
                                <p className="description">
                                    Say goodbye to complicated solutions, paper processes, and slow approval. Say hello to fast and proactive spend management.
                                </p>
                            </div>
                        </Col>
                    </Row>
                        <Row>
                            <Col md={12} className={'text-center'}>
                                <h2 className="title text-center">Features</h2>
                            </Col>
                        </Row>
                </Container>
            </div>
            <div >
                <Row>
                    <Container>
                        <Row>
                    <Col md={12}>
                        <h3 className={'text-center'}>Purchase request</h3>
                        <p>Create, review, and manage your purchase requests with complete transparency!
                        Gain full visibility about the status of your purchase requests and get instant updates whenever there are changes.
                        With cloud storage, get instant access to your product catalogs when initiating purchase requisitions.
                        Proactively define and manage budget restrictions with dynamic rules throughout the entire procure-to-pay lifecycle.</p>
                    </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                <Container fluid>
                    <Row className={'mt--4'}>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-info"><strong>Approvals on the go</strong></h5>
                                    <p>
                                        Every stakeholder has the ability to provide approvals: anytime, anywhere, on any device.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-danger"><strong>Requisitions to RFQ</strong></h4>
                                    <p>
                                        Convert your purchase requisitions to a new request for proposal (RFQ) in a click.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" className={'mt-5 pt-5'}>
                            <div className="phone-container pt-5 mt-5">
                                <img
                                    alt="..."
                                    src={require("assets/img/proc.png").default}
                                ></img>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-success"><strong>Automated routing</strong></h5>
                                    <p>
                                        Move requests through the chain of hierarchy without any manual intervention.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-warning"><strong>Policy compliance</strong></h4>
                                    <p>
                                        Enforce organizational and legal policies easily with digital purchase requisitions.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </Row>
            </div>
            <div className={'bg-dark pt-5'}>
                <Row>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h3 className={'text-white text-center'}>Purchase order</h3>
                                <p className={'text-light'}>Customize every aspect of your POs from data fields to approval workflows!
                                Ensure policy compliance and spend transparency using rule-based PO approval workflows that can be customized.
                                Initiate an enrolment process for unregistered vendors and update product catalogs right within the PO process.
                                Using custom reports and dashboards, gain actionable spend insights. Spot process inefficiencies and watch spend trends.
                                Simplify your PO process, eliminate inefficiencies and delays using a tailor-made PO tool.</p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                <Container fluid className={'bg-dark'}>
                    <Row className={'mt--4'}>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-info"><strong>Paperless processing</strong></h5>
                                    <p className={'text-light'}>
                                        Execute and manage all PO documentation effectively without a single printout.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-danger"><strong>Smart alerts</strong></h4>
                                    <p className={'text-light'}>
                                        Keep stakeholders up-to-date on any purchase order status with automated alerts.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" className={'mt-5 pt-5'}>
                            <div className="phone-container pt-5 mt-5">
                                <img
                                    alt="..."
                                    src={require("assets/img/proc.png").default}
                                ></img>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-success"><strong>Approvals on-the-go</strong></h5>
                                    <p className={'text-light'}>
                                        Track and approve purchase orders from anywhere, anytime, on any device.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-warning"><strong>Fluid forms</strong></h4>
                                    <p className={'text-light'}>
                                        Customizable form fields help you capture purchase order-related information.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </Row>
            </div>


            <div >
                <Row className={'pt-4'}>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h3 className={'text-center'}>Vendor management</h3>
                                <p>Take vendor management to a new level.
                                Build better supplier relationships with a streamlined vendor management process.
                                Register vendors quickly and easily, use multilingual catalogs, and access vendor information instantly.
                                Rate vendors using key performance metrics like policy compliance, delivery quality, timeliness, and more.
                                Give vendors the ability to easily update their information, and get alerts on order and payment details.</p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container fluid>
                        <Row className={'mt--4'}>
                            <Col md="4">
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h5 className="info-title text-info"><strong>Quick vendor screening</strong></h5>
                                        <p>
                                            Retrieve and verify supplier data like business reports, financial and credit risk data.
                                        </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h4 className="info-title text-danger"><strong>Real-time collaboration</strong></h4>
                                        <p>
                                            Instantly confirm goods receipt notices (GRNs), goods return ship notices, and more.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" className={'mt-5 pt-5'}>
                                <div className="phone-container pt-5 mt-5">
                                    <img
                                        alt="..."
                                        src={require("assets/img/proc.png").default}
                                    ></img>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h5 className="info-title text-success"><strong>All data in one place</strong></h5>
                                        <p>
                                            Access all POs, PRs, contracts, and vendor payment information, all in one place.
                                        </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h4 className="info-title text-warning"><strong>Easy vendor enrolment</strong></h4>
                                        <p>
                                            Collect key vendor information to screen them based on reputation, past performance, and more.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </div>
            <div className={'bg-dark pt-4'}>
                <Row>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h3 className={'text-white text-center'}>Purchase invoice</h3>
                                <p className={'text-light'}>Invoice matching is no longer a hassle. Streamline matching with dynamic workflows and rule-based processes. Connect invoices to contracts, POs, Proposals, and service entry sheets with automated checks. Smart alerts reach stakeholders at the right time, helping you meet deadlines easily. Speed up your invoice approval process while ensuring compliance and accuracy.</p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container fluid className={'bg-dark'}>
                        <Row className={'mt--4'}>
                            <Col md="4">
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h5 className="info-title text-info"><strong>Quick approvals</strong></h5>
                                        <p className={'text-light'}>
                                            Process all purchasing-related invoices in a single, touchless stream of digital approvals.
                                        </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h4 className="info-title text-danger"><strong>Instant access</strong></h4>
                                        <p className={'text-light'}>
                                            Retrieve any purchase invoice–current or archived–whenever you need it.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" className={'mt-5 pt-5'}>
                                <div className="phone-container pt-5 mt-5">
                                    <img
                                        alt="..."
                                        src={require("assets/img/proc.png").default}
                                    ></img>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h5 className="info-title text-success"><strong>Automated routing</strong></h5>
                                        <p className={'text-light'}>
                                            Move approvals through the chain of hierarchy without any manual intervention.
                                        </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h4 className="info-title text-warning"><strong>Track progress</strong></h4>
                                        <p className={'text-light'}>
                                            Obtain an accurate status of all your purchase invoices in real-time at a glance.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </div>

            <div >
                <Row className={'pt-4'}>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h3 className={'text-center'}>Analytics and Reporting</h3>
                                <p>Turn data into actionable insights. Retrieve actionable insights to spot purchasing trends and optimize spend. Purchasing data presented simply and concisely to drive actionable insights. Track   orders,   compliance,   delivery   quality,   and   other   key   vendor performance metrics. Generate instant reports with customizable parameters for the insights you need. Summarize purchasing data and detect trends in an accurate and timely manner.</p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container fluid>
                        <Row className={'mt--4'}>
                            <Col md="4">
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h5 className="info-title text-info"><strong>Charts, filters, and pivots</strong></h5>
                                        <p>
                                            Manipulate   and   visualize   purchase   data   for   the   most   convenient analysis.
                                        </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h4 className="info-title text-danger"><strong>Heatmaps</strong></h4>
                                        <p>
                                            Gain insights into process gaps and inefficiencies, using a visual aid.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" className={'mt-5 pt-5'}>
                                <div className="phone-container pt-5 mt-5">
                                    <img
                                        alt="..."
                                        src={require("assets/img/proc.png").default}
                                    ></img>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h5 className="info-title text-success"><strong>Intuitive interface</strong></h5>
                                        <p>
                                            Study complex, intricate data with a simple, visual-rich interface.
                                        </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="description">
                                        <h4 className="info-title text-warning"><strong>Cloud storage</strong></h4>
                                        <p>
                                            Retrieve any document — open, closed, or archived — whenever you need it.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </div>


            {/*<div className="features-5">*/}
            {/*    <Container>*/}
            {/*        <Row>*/}
            {/*            <Col md={12} className={'text-center'}>*/}
            {/*                <h2 className="title text-center">Features</h2>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col md={12}>*/}
            {/*                <h3>Purchase request</h3>*/}
            {/*                <p>Create, review, and manage your purchase requests with complete transparency!</p>*/}
            {/*                <p>Gain full visibility about the status of your purchase requests and get instant updates whenever there are changes.</p>*/}
            {/*                <p>With cloud storage, get instant access to your product catalogs when initiating purchase requisitions.</p>*/}
            {/*                <p>Proactively define and manage budget restrictions with dynamic rules throughout the entire procure-to-pay lifecycle.</p>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col md="7">*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons location_world"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Approvals on the go </h4>*/}
            {/*                        <p>*/}
            {/*                            Every stakeholder has the ability to provide approvals: anytime, anywhere, on any device.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons sport_user-run"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Requisitions to RFQ</h4>*/}
            {/*                        <p>*/}
            {/*                            Convert your purchase requisitions to a new request for proposal (RFQ) in a click.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons ui-2_time-alarm"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Automated routing</h4>*/}
            {/*                        <p>*/}
            {/*                            Move requests through the chain of hierarchy without any manual intervention.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons ui-2_time-alarm"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Policy compliance</h4>*/}
            {/*                        <p>*/}
            {/*                            Enforce organizational and legal policies easily with digital purchase requisitions.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*            <Col md="5">*/}
            {/*                <div className="tablet-container mt-5">*/}
            {/*                    <img*/}
            {/*                        alt="..."*/}
            {/*                        src={require("assets/img/proc.png").default}*/}
            {/*                    ></img>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}

            <div className="testimonials-2 mt-0 pt-0">
                <Container>
                    <Row>
                        <Col md="12">
                            <Carousel
                                activeIndex={activeIndex}
                                next={next}
                                previous={previous}
                            >
                                {items.map((item) => {
                                    return (
                                        <CarouselItem
                                            onExiting={onExiting}
                                            onExited={onExited}
                                            key={item.src}
                                        >
                                            {item.content}
                                        </CarouselItem>
                                    );
                                })}
                                <a
                                    className="carousel-control-prev"
                                    data-slide="prev"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        previous();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    data-slide="next"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        next();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                                </a>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="pricing-2 bg-light" id="pricing-2">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="6">
                            <h2 className="title">Pick the best plan for you</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Card className="card-pricing card-plain">
                                <CardBody>
                                    <h5 className="category">Business Standard</h5>
                                    <CardTitle tag="h1">
                                        <small>֏</small>
                                        49 900
                                    </CardTitle>
                                    <h3>
                                        <small>Monthly</small>
                                    </h3>
                                    <ul>
                                        <li>
                                            10 users
                                        </li>
                                        <li>
                                            Vendor Portal
                                        </li>
                                        <li>
                                            Stock Management
                                        </li>
                                        <li>
                                            Mobile Support
                                        </li>
                                    </ul>
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Sign Up
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card
                                className="card-pricing card-background card-raised"
                                style={{
                                    backgroundImage:
                                        "url(" + require("assets/img/pricing3.jpg").default + ")",
                                }}
                            >
                                <CardBody>
                                    <h5 className="category text-info">Business Pro</h5>
                                    <CardTitle tag="h1" className={'text-info'}>
                                        <small>֏</small>
                                        89 900
                                    </CardTitle>
                                    <h3>
                                        <small>Monthly</small>
                                    </h3>
                                    <ul>
                                        <li>
                                            1 department
                                        </li>
                                        <li>
                                            Everything in Business Standard
                                        </li>
                                        <li>
                                            Vendor Management
                                        </li>
                                        <li>
                                            Multiple Storage
                                        </li>
                                    </ul>
                                    <Button
                                        className="btn-neutral btn-round"
                                        color="default"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Sign Up
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-pricing card-plain">
                                <CardBody>
                                    <h5 className="category">Advanced</h5>
                                    <h3>
                                        <small>Unlimited users</small>
                                    </h3>
                                    <ul>
                                        <li>
                                            Everything in Business Pro
                                        </li>
                                        <li>
                                            Custom Functions
                                        </li>
                                    </ul>
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Call for more
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(" + require("assets/img/bg32.jpg").default + ")",
                    }}
                />
                <div className="content-center">
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h3>
                                Give procurement a boost with carefully curated features.
                            </h3>
                            <Button color={'danger'}>Contact us</Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                >
                    <InitMap />
                </div>
                <div>
                    <Row>
                        <Col lg="5" md="10" className="mt-5 ml-5">
                            <Card className="card-contact card-raised">
                                <Form id="contact-form" method="post" role="form">
                                    <CardHeader className="text-center">
                                        <CardTitle tag="h4">Contact Us</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="5">
                                                <div className="info info-horizontal">
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons tech_mobile"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Give us a ring</h5>
                                                        <p>
                                                            +37495 222238 <br></br>
                                                            Mon - Fri, 9:00 – 18:00
                                                        </p>
                                                        <p><small>Email:</small> info@bisflow.io</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="7">
                                                <div className="info info-horizontal">
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons location_pin"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Find us at the office</h5>
                                                        <p>
                                                            34 Business Centre, 2d Flore, <br></br>
                                                            34 Garegin Nzhdeh street, <br></br>
                                                            Yerevan, Armenia
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default MainLanding;