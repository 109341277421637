import React from "react";
import {
    Button,
    Card, CardBody, CardFooter, CardHeader, CardTitle,
    Carousel, CarouselItem,
    Col,
    Collapse,
    Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import InitMap from "./InitMap";

const MapWrapper = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let lat = "40.748817";
        let lng = "-73.985428";
        const myLatlng = new google.maps.LatLng(lat, lng);
        const mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel: false,
            zoomControl: true,
            styles: [
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
                },
                {
                    featureType: "landscape",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#ffffff" }, { lightness: 17 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
                },
                {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 18 }],
                },
                {
                    featureType: "road.local",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 16 }],
                },
                {
                    featureType: "poi",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#dedede" }, { lightness: 21 }],
                },
                {
                    elementType: "labels.text.stroke",
                    stylers: [
                        { visibility: "on" },
                        { color: "#ffffff" },
                        { lightness: 16 },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        { saturation: 36 },
                        { color: "#333333" },
                        { lightness: 40 },
                    ],
                },
                { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#fefefe" }, { lightness: 20 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
                },
            ],
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Now UI Kit PRO React!",
        });

        const contentString =
            '<div class="info-window-content"><h2>Now UI Kit PRO React</h2>' +
            "<p>A premium Admin for React, Reactstrap, and React Hooks.</p></div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
        });
    });
    return (
        <>
            <div style={{ height: `100%` }} ref={mapRef}></div>
        </>
    );
};

const items = [
    {
        src: "0",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons arrows-1_cloud-upload-94'}></i>
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Go 100% paperless; no more paper coupons."
                    </h5>
                </CardBody>
            </Card>
        ),
    },
    {
        src: "1",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons design-2_ruler-pencil'} />
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Simplify budgeting, forecast more accurately, and improve cooperation with vendors."
                    </h5>
                </CardBody>
            </Card>
        ),
    }
];

function MainLanding() {

    const [navbarOpen1, setNavbarOpen1] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [first1Focus, setFirst1Focus] = React.useState(false);
    const [last1Focus, setLast1Focus] = React.useState(false);
    const [email1Focus, setEmail1Focus] = React.useState(false);
    const [first2Focus, setFirst2Focus] = React.useState(false);
    const [email2Focus, setEmail2Focus] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    return (
        <div className="cd-section" id="headers">
            <div className="header-3">
                <Navbar
                    className="navbar-transparent bg-info navbar-absolute"
                    expand="lg"
                >
                    <Container>
                        <div className="navbar-translate">
                            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img
                                    alt="..."
                                    style={{width: '120px', height: '40px'}}
                                    src={require("assets/img/logo_white.png").default}
                                ></img>
                            </NavbarBrand>
                        </div>
                    </Container>
                </Navbar>
            </div>
                <div className="page-header page-header-small">
                    <div
                        className="page-header-image"
                        style={{
                            backgroundImage: "url(" + require("assets/img/bg37.jpg").default + ")",
                        }}
                    />
                    <div className="content">
                        <Container className="text-left">
                            <Row>
                                <Col className="ml-auto mr-auto text-right mt-5" md="6">
                                    <h1 className={'title'} style={{textAlign: 'start'}}>Expense Management</h1>
                                    <h4 style={{textAlign: 'justify'}}>
                                        Bisflow Expense Management platform is designed to automate and simplify the company's expenses incurred to ensure the daily operation of employees.
                                    </h4>
                                </Col>
                                <Col md="5">
                                    <div className="iframe-container">
                                        <iframe
                                            title="myIframe"
                                            allowFullScreen=""
                                            frameBorder="0"
                                            height="250"
                                            src="https://www.youtube.com/embed/5ZdW9IrGh1I?rel=0&amp;controls=0&amp;showinfo=0"
                                        ></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            </div>
            <div className="features-3">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10">
                            <h2 className="title">Simpler. Smarter. Faster.</h2>
                            <h4 className="description" style={{textAlign: 'justify'}}>
                                Empowering users to do more is the fundamental goal of all automation. Automating repetitive or consistent tasks frees people to do more strategic, meaningful and valuable work.
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-success icon-circle">
                                    <i className="now-ui-icons tech_watch-time"></i>
                                </div>
                                <h4 className="info-title">Time saving</h4>
                                <p className="description">
                                    Using our virtual coupon you will get rid of the headache of daily work with small and thousands of paper coupons. It will also reduce the time required to purchase coupons and assign staff by up to 80%.
                                </p>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-primary icon-circle">
                                    <i className="now-ui-icons objects_key-25"></i>
                                </div>
                                <h4 className="info-title">Control</h4>
                                <p className="description">
                                    Create approval workflows to fit your company’s structure and customize your expense policy rules to flag expenses that need a manager’s review.
                                </p>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-info icon-circle">
                                    <i className="now-ui-icons ui-1_lock-circle-open"></i>
                                </div>
                                <h4 className="info-title">Security</h4>
                                <p className="description">
                                    Security and Compliance is at the core of what we do. Bisflow follows strict global guidelines for securing your company data. What does all this mean; simply, your data is managed and safeguarded at the highest level of global industry security standards.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'bg-light'}>
                <Row>
                    <Col md={12} className={'text-center'}>
                        <h2 className="title text-center">Features</h2>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Row>
                    <Col md={12} className={'text-center'}>
                        <p>We provide end-to-end payment technology for various industries directly connecting merchants to businesses.</p>
                    </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                <Container fluid>
                    <Row className={'mt--4'}>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-info"><strong>E-coupon</strong></h5>
                                    <p>
                                        Bisflow simplifies your expense management process through integrated payment solutions and virtual coupons.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-danger"><strong>Tracker</strong></h4>
                                    <p>
                                        Bisflow Mileage Tracker will track your mileage route using your mobile device’s GPS and allows users to create a mileage expense with a single swipe. Each mileage expense has a copy of the routing information and map viewable to users and expense managers.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" className={'mt-5 pt-5'}>
                            <div className="phone-container pt-5 mt-5">
                                <img
                                    alt="..."
                                    src={require("assets/img/payva.png").default}
                                ></img>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h5 className="info-title text-success"><strong>Financial policy</strong></h5>
                                    <p>
                                        Employees can only spend money that’s in their account, so you never have to worry about getting a supersized bill at the end of the month.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="description">
                                    <h4 className="info-title text-warning"><strong>Analytic & Report</strong></h4>
                                    <p>
                                        Generate instant reports with customizable parameters for the insights you need. Analyze expense data and detect trends in an accurate and timely manner.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </Row>
            </div>


            {/*<div className="features-5">*/}
            {/*    <Container>*/}
            {/*        <Row>*/}
            {/*            <Col md={12} className={'text-center'}>*/}
            {/*                <h2 className="title text-center">Features</h2>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col md={12}>*/}
            {/*                <h3>Purchase request</h3>*/}
            {/*                <p>Create, review, and manage your purchase requests with complete transparency!</p>*/}
            {/*                <p>Gain full visibility about the status of your purchase requests and get instant updates whenever there are changes.</p>*/}
            {/*                <p>With cloud storage, get instant access to your product catalogs when initiating purchase requisitions.</p>*/}
            {/*                <p>Proactively define and manage budget restrictions with dynamic rules throughout the entire procure-to-pay lifecycle.</p>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col md="7">*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons location_world"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Approvals on the go </h4>*/}
            {/*                        <p>*/}
            {/*                            Every stakeholder has the ability to provide approvals: anytime, anywhere, on any device.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons sport_user-run"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Requisitions to RFQ</h4>*/}
            {/*                        <p>*/}
            {/*                            Convert your purchase requisitions to a new request for proposal (RFQ) in a click.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons ui-2_time-alarm"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Automated routing</h4>*/}
            {/*                        <p>*/}
            {/*                            Move requests through the chain of hierarchy without any manual intervention.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="info info-horizontal">*/}
            {/*                    <div className="icon icon-info icon-circle">*/}
            {/*                        <i className="now-ui-icons ui-2_time-alarm"></i>*/}
            {/*                    </div>*/}
            {/*                    <div className="description">*/}
            {/*                        <h4 className="info-title">Policy compliance</h4>*/}
            {/*                        <p>*/}
            {/*                            Enforce organizational and legal policies easily with digital purchase requisitions.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*            <Col md="5">*/}
            {/*                <div className="tablet-container mt-5">*/}
            {/*                    <img*/}
            {/*                        alt="..."*/}
            {/*                        src={require("assets/img/proc.png").default}*/}
            {/*                    ></img>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}

            <div className="testimonials-2 mt-0 pt-0">
                <Container>
                    <Row>
                        <Col md="12">
                            <Carousel
                                activeIndex={activeIndex}
                                next={next}
                                previous={previous}
                            >
                                {items.map((item) => {
                                    return (
                                        <CarouselItem
                                            onExiting={onExiting}
                                            onExited={onExited}
                                            key={item.src}
                                        >
                                            {item.content}
                                        </CarouselItem>
                                    );
                                })}
                                <a
                                    className="carousel-control-prev"
                                    data-slide="prev"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        previous();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    data-slide="next"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        next();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                                </a>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="pricing-2 bg-light" id="pricing-2">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="6">
                            <h2 className="title">Contact us for pricing</h2>
                        </Col>
                    </Row>
                    <Row className={'justify-content-center text-center content-center'}>
                        <Col md="6">
                            <Card className="card-pricing card-plain">
                                <CardBody>
                                    <h5 className="category">Business</h5>
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Call for more
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(" + require("assets/img/bg32.jpg").default + ")",
                    }}
                />
                <div className="content-center">
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h3>
                                Expense management has never been so good. Join now!
                            </h3>
                            <Button color={'danger'}>Contact us</Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                >
                    <InitMap />
                </div>
                <div>
                    <Row>
                        <Col lg="5" md="10" className="mt-5 ml-5">
                            <Card className="card-contact card-raised">
                                <Form id="contact-form" method="post" role="form">
                                    <CardHeader className="text-center">
                                        <CardTitle tag="h4">Contact Us</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="5">
                                                <div className="info info-horizontal">
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons tech_mobile"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Give us a ring</h5>
                                                        <p>
                                                            +37495 222238 <br></br>
                                                            Mon - Fri, 9:00 – 18:00
                                                        </p>
                                                        <p><small>Email:</small> info@bisflow.io</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="7">
                                                <div className="info info-horizontal">
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons location_pin"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Find us at the office</h5>
                                                        <p>
                                                            34 Business Centre, 2d Flore, <br></br>
                                                            34 Garegin Nzhdeh street, <br></br>
                                                            Yerevan, Armenia
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default MainLanding;