import React from "react";

// reactstrap components
import {Row, Col, Button} from "reactstrap";

// core components

function AboutUsHeader() {
  return (
    <>
      <div className="page-header page-header-small">
        <div
            className="page-header-image"
            style={{
              backgroundImage: "url(" + require("assets/img/bg32.jpg").default + ")",
            }}
        />
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h3>
                Your digital workplace is one click away.
              </h3>
              <Button color={'danger'}>Contact us</Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
