import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Carousel,
    CarouselItem,
    Col, Collapse,
    Container,
    Navbar,
    Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Nav, NavbarBrand, NavItem, NavLink,
    Row
} from "reactstrap";
import AboutUsHeader from "../../components/Headers/AboutUsHeader";

import InitMap from "./InitMap";

const MapWrapper = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let lat = "40.748817";
        let lng = "-73.985428";
        const myLatlng = new google.maps.LatLng(lat, lng);
        const mapOptions = {
            zoom: 12,
            center: myLatlng,
            scrollwheel: false,
            zoomControl: true,
            styles: [
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
                },
                {
                    featureType: "landscape",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#ffffff" }, { lightness: 17 }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
                },
                {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 18 }],
                },
                {
                    featureType: "road.local",
                    elementType: "geometry",
                    stylers: [{ color: "#ffffff" }, { lightness: 16 }],
                },
                {
                    featureType: "poi",
                    elementType: "geometry",
                    stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#dedede" }, { lightness: 21 }],
                },
                {
                    elementType: "labels.text.stroke",
                    stylers: [
                        { visibility: "on" },
                        { color: "#ffffff" },
                        { lightness: 16 },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        { saturation: 36 },
                        { color: "#333333" },
                        { lightness: 40 },
                    ],
                },
                { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.fill",
                    stylers: [{ color: "#fefefe" }, { lightness: 20 }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
                },
            ],
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Now UI Kit PRO React!",
        });

        const contentString =
            '<div class="info-window-content"><h2>Now UI Kit PRO React</h2>' +
            "<p>A premium Admin for React, Reactstrap, and React Hooks.</p></div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
        });
    });
    return (
        <>
            <div style={{ height: `100%` }} ref={mapRef}></div>
        </>
    );
};

const items = [
    {
        src: "0",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons ui-2_chat-round'}></i>
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Collaborating   in   a   digital   workplace   means   better   sharing   and understanding across boundaries."
                    </h5>
                </CardBody>
            </Card>
        ),
    },
    {
        src: "1",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons business_chart-bar-32'} />
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "A simple, optimal and efficient platform to manage, track and control all of your business."
                    </h5>
                </CardBody>
            </Card>
        ),
    },
    {
        src: "2",
        altText: "",
        caption: "",
        content: (
            <Card className="card-testimonial card-plain">
                <div className="info info-vertical">
                    <div className="icon icon-info icon-circle">
                        <i className={'now-ui-icons business_globe'} />
                    </div>
                </div>
                <CardBody>
                    <h5 className="card-description">
                        "Whether   you   are   leading   the   whole   company   or   a   team,   a   digital workplace gives you the flexibility, power, and transparency you need."
                    </h5>
                </CardBody>
            </Card>
        ),
    },
];

function Main() {

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [first2Focus, setFirst2Focus] = React.useState(false);
    const [email2Focus, setEmail2Focus] = React.useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };

    return <div className="wrapper">
        <div className="cd-section" id="headers">
            <div className="header-3">
                <Navbar
                    className="navbar-transparent bg-info navbar-absolute"
                    expand="lg"
                >
                    <Container>
                        <div className="navbar-translate">
                            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img
                                    alt="..."
                                    style={{width: '120px', height: '40px'}}
                                    src={require("assets/img/logo_white.png").default}
                                ></img>
                            </NavbarBrand>
                        </div>
                    </Container>
                </Navbar>
            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(" + require("assets/img/bg37.jpg").default + ")",
                    }}
                />
                <div className="content">
                    <Container className="text-left">
                        <Row>
                            <Col className="ml-auto mr-auto text-right mt-5" md="6">
                                <h1 className={'title'} style={{textAlign: 'start'}}>Digital Workplace</h1>
                                <h4 style={{textAlign: 'justify'}}>
                                    In this complex world, simplicity holds power beyond imagination. Here’s a glimpse into the digital workplace platform of the future and what it can do for your business.
                                </h4>
                            </Col>
                            <Col md="5">
                                <div className="iframe-container">
                                    <iframe
                                        title="myIframe"
                                        allowFullScreen=""
                                        frameBorder="0"
                                        height="250"
                                        src="https://www.youtube.com/embed/0L8Ntzm4NSU?rel=0&amp;controls=0&amp;showinfo=0"
                                    ></iframe>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
        </div>
        <div className="cd-section" id="features">
            <div className="features-1">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="1"/>
                        <Col className="ml-auto mr-auto" md="10">
                            <h2 className="title ml-auto mr-auto">This is where all your work happens</h2>
                            <h4 className="description">
                                Bisflow Digital Workplace has everything your team needs to manage any kind of work that comes their way. From highly structured and sophisticated processes to quick project boards, all of your work can live on one platform.
                            </h4>
                        </Col>
                        <Col className="ml-auto mr-auto" md="1"/>
                    </Row>
                    {/*<br/>*/}
                    {/*<Row>*/}
                    {/*    <Col md="10">*/}
                    {/*        <div className="info info-hover">*/}
                    {/*            <div className="icon icon-info">*/}
                    {/*                <i className="now-ui-icons ui-2_chat-round"></i>*/}
                    {/*            </div>*/}
                    {/*            <h4 className="info-title">Simpler</h4>*/}
                    {/*            <p className="description">*/}
                    {/*                The key motivator for the workforce of the future is happy workplaces andthey will follow it to wherever it takes them. Be the workplace that lets talentfind you.*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col md="4">*/}
                    {/*        <div className="info info-hover">*/}
                    {/*            <div className="icon icon-success">*/}
                    {/*                <i className="now-ui-icons business_chart-pie-36"></i>*/}
                    {/*            </div>*/}
                    {/*            <h4 className="info-title">Smarter</h4>*/}
                    {/*            <p className="description">*/}
                    {/*                The   dynamics   of   work   are   changing.   Talent   gravitates   towards   low   stressworkplaces   that   offer   work-life   harmony   and   a   great   fulfilling   workexperience.*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col md="4">*/}
                    {/*        <div className="info info-hover">*/}
                    {/*            <div className="icon icon-warning">*/}
                    {/*                <i className="now-ui-icons design-2_ruler-pencil"></i>*/}
                    {/*            </div>*/}
                    {/*            <h4 className="info-title">Faster</h4>*/}
                    {/*            <p className="description">*/}
                    {/*                Efficiency at work will be the buzzword of the next decade. Technology canmake   work   flow   faster   and   make   it   more   enjoyable   while   automatingmundane tasks.*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </div>
        </div>
        <div className="features-5">
            <Container>
                <Row>
                    <Col md="6">
                        <div className="info info-horizontal">
                            <h2>Values</h2>
                            <div className="icon icon-info icon-circle">
                                <i className="now-ui-icons gestures_tap-01"/>
                            </div>
                            <div className="description">
                                <h4 className="info-title">Simpler</h4>
                                <p>
                                    The key motivator for the workforce of the future is happy workplaces and they will follow it to wherever it takes them. Be the workplace that lets talent find you.
                                </p>
                            </div>
                        </div>
                        <div className="info info-horizontal">
                            <div className="icon icon-info icon-circle">
                                <i className="now-ui-icons tech_laptop"></i>
                            </div>
                            <div className="description">
                                <h4 className="info-title">Smarter</h4>
                                <p>
                                    The dynamics of work are changing. Talent gravitates towards low stress workplaces that offer work-life harmony and a great fulfilling work experience.
                                </p>
                            </div>
                        </div>
                        <div className="info info-horizontal">
                            <div className="icon icon-info icon-circle">
                                <i className="now-ui-icons tech_watch-time"></i>
                            </div>
                            <div className="description">
                                <h4 className="info-title">Faster</h4>
                                <p>
                                    Efficiency at work will be the buzzword of the next decade. Technology can make work flow faster and make it more enjoyable while automating mundane tasks.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="tablet-container mt-5 pt-5">
                            <img
                                alt="..."
                                src={require("assets/img/pad_bisflow.png").default}
                            ></img>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="projects-2 bg-light">
            <Container fluid>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">Products</h2>
                        <h5 className="description">
                            A simple, optimal and efficient platform to manage, track and control all of your business.
                        </h5>
                        <div className="section-space"></div>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <Card className="card-plain">
                            <a
                                href="/procurement"
                                target="_blank"
                            >
                                <div className="card-image">
                                    <img
                                        alt="..."
                                        className="rounded"
                                        src={require("assets/img/proc.png").default}
                                    ></img>
                                </div>
                            </a>
                            <CardBody>
                                <a
                                    href="/procurement"
                                    target="_blank"
                                >
                                    <CardTitle tag="h4">Procurement</CardTitle>
                                </a>
                                <h6 className="category text-info">Procurement</h6>
                                <p className="card-description">
                                    Take control of your procure-to-pay process with our simple and customizable procurement platform (purchase requisitions, purchase orders, vendor management, purchase invoices, analytics etc.).
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card className="card-plain">
                            <a
                                href="/clm"
                                target="_blank"
                            >
                                <div className="card-image">
                                    <img
                                        alt="..."
                                        className="rounded"
                                        src={require("assets/img/procurement.png").default}
                                    ></img>
                                </div>
                            </a>
                            <CardBody>
                                <a
                                    href="/clm"
                                    target="_blank"
                                >
                                    <CardTitle tag="h4">Contract Lifecycle Management</CardTitle>
                                </a>
                                <h6 className="category text-info">Legal</h6>
                                <p className="card-description">
                                    Streamline the agreement lifecycle from end-to-end. Automate manual tasks, align complex workflows, eliminate errors and risks with CLMs smart contract management and digital signature.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card className="card-plain">
                            <a
                                href="/expense"
                                target="_blank"
                            >
                                <div className="card-image">
                                    <img
                                        alt="..."
                                        className="rounded"
                                        src={require("assets/img/payva.png").default}
                                    ></img>
                                </div>
                            </a>
                            <CardBody>
                                <a
                                    href="/expense"
                                    target="_blank"
                                >
                                    <CardTitle tag="h4">E-coupon</CardTitle>
                                </a>
                                <h6 className="category text-info">Financial management</h6>
                                <p className="card-description">
                                    We give businesses the power to automate, connect and simplify their expenses with our next generation mobile application (e-coupon).
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card className="card-plain">
                            <a
                                href="/requests"
                                target="_blank"
                            >
                                <div className="card-image">
                                    <img
                                        alt="..."
                                        className="rounded"
                                        src={require("assets/img/requestManagement.png").default}
                                    ></img>
                                </div>
                            </a>
                            <CardBody>
                                <a
                                    href="/requests"
                                    target="_blank"
                                >
                                    <CardTitle tag="h4">Request management</CardTitle>
                                </a>
                                <h6 className="category text-info">Track</h6>
                                <p className="card-description">
                                    Stay in full control of all your projects and keep track of all the moving parts. Gain full visibility about the status of your requests and get instant updates whenever there are changes.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="testimonials-2 mt-0 pt-0">
            <Container>
                <Row>
                    <Col md="12">
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                        >
                            {items.map((item) => {
                                return (
                                    <CarouselItem
                                        onExiting={onExiting}
                                        onExited={onExited}
                                        key={item.src}
                                    >
                                        {item.content}
                                    </CarouselItem>
                                );
                            })}
                            <a
                                className="carousel-control-prev"
                                data-slide="prev"
                                href="#pablo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    previous();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-left"></i>
                            </a>
                            <a
                                className="carousel-control-next"
                                data-slide="next"
                                href="#pablo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    next();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-right"></i>
                            </a>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
        <AboutUsHeader />
        <div className="page-header page-header-small">
            <div
                className="page-header-image"
                >
                <InitMap />
            </div>
            <div>
                <Row>
                    <Col lg="5" md="10" className="mt-5 ml-5">
                <Card className="card-contact card-raised">
                    <Form id="contact-form" method="post" role="form">
                        <CardHeader className="text-center">
                            <CardTitle tag="h4">Contact Us</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="5">
                                    <div className="info info-horizontal">
                                        <div className="icon icon-info">
                                            <i className="now-ui-icons tech_mobile"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Give us a ring</h5>
                                            <p>
                                                +37495 222238 <br></br>
                                                Mon - Fri, 9:00 – 18:00
                                            </p>
                                            <p><small>Email:</small> info@bisflow.io</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="7">
                                    <div className="info info-horizontal">
                                        <div className="icon icon-info">
                                            <i className="now-ui-icons location_pin"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Find us at the office</h5>
                                            <p>
                                                34 Business Centre, 2d Flore, <br></br>
                                                34 Garegin Nzhdeh street, <br></br>
                                                Yerevan, Armenia
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            </Col>
                </Row>
            </div>
        </div>
    </div>
}

export default Main;